import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Message, Types as MessageTypes } from '../ui/form/Message';

import Window from '../../img/window.svg';
import Phone from '../../img/phone.svg';
import Email from '../../img/email.svg';
import Book from '../../img/book.svg';
import Tick from '../../img/tick.svg';
import Error from '../../img/error.svg';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export const ContactForm: FC = () => {
	const { register, handleSubmit, errors } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const [message, setMessage] = useState('');

	const encode = data => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
			.join('&');
	};

	const onSubmit = async data => {
		setSubmitting(true);
		setMessage(null);

		const response = await fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'order', ...data }),
		});

		if (response.ok) {
			setMessage(MessageTypes.SUCCESS);
		} else {
			setMessage(MessageTypes.ERROR);
		}

		setSubmitting(false);
	};

	return (
		<>
			{message == MessageTypes.SUCCESS ? (
				<div className="b-message b-message--success">
					<Tick />
					<p>Děkujeme vám za objednávku auditu rychlosti webu od PageSpeed.cz!</p>
					<p>
						V dalším kroku vás co nevidět budeme kontaktovat s dotazníkem, jehož účelem je zjištění
						technických a organizačních parametrů vašeho projektu.
					</p>
				</div>
			) : (
				<>
					{message == MessageTypes.ERROR && (
						<div className="b-message b-message--error">
							<Error />
							Formulář se nepodařilo odeslat. Zkuste to prosím znovu nebo nás kontaktujte e-mailem na{' '}
							<OutboundLink href="mailto:info@pagespeed.cz">info@pagespeed.cz</OutboundLink>
						</div>
					)}
					<form
						name="order"
						className="f-order"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						method="post"
						onSubmit={handleSubmit(onSubmit)}
					>
						<input type="hidden" name="form-name" value="order" />
						<div className="grid">
							<div className="grid__cell">
								<p className="inp">
									<label htmlFor="web" className="inp-label">
										Adresa vašeho webu, který chcete zrychlit
									</label>
									<span className="inp-fix inp-icon">
										<Window />
										<input
											type="text"
											id="web"
											name="web"
											placeholder="např. www.vasweb.cz"
											className="inp-text inp-text--web"
											ref={register({
												required: 'Zadejte prosím adresu webu.',
											})}
										/>
										{errors.web && (
											<Message type={MessageTypes.ERROR}>{errors.web.message}</Message>
										)}
									</span>
								</p>
							</div>
							<div className="grid__cell size--6-12@md">
								<p className="inp">
									<label htmlFor="email" className="inp-label">
										Váš kontaktní e-mail
									</label>
									<span className="inp-fix inp-icon">
										<Email />
										<input
											type="text"
											id="email"
											name="email"
											placeholder="@"
											className="inp-text"
											ref={register({
												required: 'Zadejte prosím váš e-mail.',
											})}
										/>
										{errors.email && (
											<Message type={MessageTypes.ERROR}>{errors.email.message}</Message>
										)}
									</span>
								</p>
							</div>
							<div className="grid__cell size--6-12@md">
								<p className="inp">
									<label htmlFor="phone" className="inp-label">
										Váš telefon
									</label>
									<span className="inp-fix inp-icon">
										<Phone />
										<input
											type="text"
											id="phone"
											name="phone"
											placeholder="např. +420 123 456 789"
											className="inp-text"
											ref={register({
												required: 'Zadejte prosím váš telefon.',
											})}
										/>
										{errors.phone && (
											<Message type={MessageTypes.ERROR}>{errors.phone.message}</Message>
										)}
									</span>
								</p>
							</div>
							<div className="grid__cell">
								<button className="btn btn--block" type="submit" disabled={submitting}>
									<span className="btn__text">
										<span className="item-icon">
											<Book />
											Objednat audit webu
										</span>
									</span>
								</button>
							</div>
						</div>
					</form>
				</>
			)}
		</>
	);
};
