import { Layout } from '../components/ui/Layout';
import React from 'react';
import { RowMain } from '../components/layout/RowMain';
import { CarouselItem } from '../components/ui/block/CarouselItem';
import Audit from '../img/audit.svg';
import Chat from '../img/chat.svg';
import Data from '../img/data.svg';
import Settings from '../img/settings.svg';
import Clipboard from '../img/clipboard.svg';
import VzhuruDolu from '../img/vzhurudolu-logo.svg';
import Superkoders from '../img/superkoders-logo.svg';
import { ContactForm } from '../components/organisms/ContactForm';
import loadable from '@loadable/component';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Helmet = loadable(() => import('react-helmet'));
const Header = loadable(() => import('../components/layout/Header'));
const Paper = loadable(() => import('../components/layout/Paper'));
const Carousel = loadable(() => import('../components/organisms/Carousel'));

const analysisItems = [
	{ id: 50, img: '/img/report@2x', altText: 'Obrázek analýzy 1' },
	{ id: 51, img: '/img/report2@2x', altText: 'Obrázek analýzy 2' },
	{ id: 52, img: '/img/report3@2x', altText: 'Obrázek analýzy 3' },
	{ id: 53, img: '/img/report4@2x', altText: 'Obrázek analýzy 4' },
	{ id: 54, img: '/img/report5@2x', altText: 'Obrázek analýzy 5' },
	{ id: 54, img: '/img/report6@2x', altText: 'Obrázek analýzy 6' },
];

const clientItems = [
	{ id: 60, img: '/img/dobrovsky.png', width: 191, height: 47, altText: 'Knihy Dobrovský' },
	{ id: 61, img: '/img/sazka.png', width: 183, height: 59, altText: 'Sazka' },
	{ id: 62, img: '/img/rohlik.png', width: 174, height: 92, altText: 'rohlik.cz' },
	{ id: 63, img: '/img/smarty.png', width: 100, height: 82, altText: 'Smarty' },
	{ id: 64, img: '/img/livesport.png', width: 210, height: 46, altText: 'Livesport' },
];

const serviceItems = [
	{
		id: 70,
		ico: <Settings />,
		img: '/img/img1-1.svg',
		imgWidth: '517',
		imgHeight: '284',
		title: 'Správné nastavení měřících nástrojů',
		text:
			'Nastavíme nástroj SpeedCurve pro pravidelná syntetická měření. Dostanete také dashboard z Chrome UX Reportu pro měření skutečných uživatelů.',
		link: 'https://superkoders.com/',
		linkText: 'Ukázka nastavení',
	},
	{
		id: 71,
		ico: <Audit />,
		img: '/img/img2-1.svg',
		imgWidth: '462',
		imgHeight: '309',
		title: 'Shrnutí nejdůležitějšího pro majitele webu',
		text:
			'V&nbsp;manažerském shrnutí dostanete stav rychlosti webu v&nbsp;kontextu trhu a konkurentů. Zjistíte, jak velký problém v&nbsp;rychlosti máte a zda se vám vyplatí jej řešit.',
		link: 'https://superkoders.com/',
		linkText: 'Ukázka shrnutí auditu',
	},
	{
		id: 72,
		ico: <Data />,
		img: '/img/img3-1.svg',
		imgWidth: '468',
		imgHeight: '306',
		title: 'Úkoly pro vývojáře',
		text:
			'Vývojáři dostanou 15–20 nejdůležitějších bodů řazených podle priority k&nbsp;nápravě, včetně návodu na odstranění a doporučených nástrojů.',
		link: 'https://superkoders.com/',
		linkText: 'Ukázka doporučení',
	},
	{
		id: 73,
		ico: <Chat />,
		img: '/img/img4-1.svg',
		imgWidth: '480',
		imgHeight: '300',
		title: 'Osobní konzultace',
		text:
			'Získáte 2 hodiny konzultací <a href="#mmichalek">Martina Michálka</a> nebo <a href="#mmatuska">Michala Matušky</a>. Osobně nebo na dálku.',
		link: 'https://superkoders.com/',
		linkText: 'Kdo vám odborně pomůže',
	},
];

const listAnalysisItems = analysisItems.map(item => (
	<CarouselItem key={item.id}>
		<figure className="c-analysis__link">
			<picture>
				<source data-srcset={`${item.img}.webp`} type="image/webp" />
				<img data-src={`${item.img}.jpg`} alt={item.altText} className="lazyload" />
				<noscript>
					<img src={`${item.img}.jpg`} alt={item.altText} />
				</noscript>
			</picture>
		</figure>
	</CarouselItem>
));

const listServiceItems = serviceItems.map(item => (
	<div key={item.id} className="c-services__item">
		<article className="b-service">
			<div className="b-service__inner">
				<h3 className="b-service__title">{item.title}</h3>
				<div className="b-service__text">
					<p dangerouslySetInnerHTML={{ __html: item.text }}></p>
				</div>
			</div>
			<div className="b-service__img">
				<img data-src={item.img} alt="" className="lazyload" width={item.imgWidth} height={item.imgHeight} />
			</div>
		</article>
	</div>
));

const listClientItems = clientItems.map(item => (
	<li key={item.id} className="c-clients__item">
		<img data-src={item.img} width={item.width} height={item.height} alt={item.altText} className="lazyload" />
		<noscript>
			<img src={item.img} width={item.width} height={item.height} alt={item.altText} />
		</noscript>
	</li>
));

export default () => (
	<Layout>
		<Helmet>
			<title>Audit – PageSpeed.cz</title>
		</Helmet>
		<Header showTextAudit />
		<Paper className="b-paper--text">
			<div className="c-clients">
				<h2 className="c-clients__title u-text-center u-color-blue">
					Pomohli jsme
					<small className="u-block">zrychlit web desítkám malých i&nbsp;velkých firem</small>
				</h2>
				<ul className="c-clients__list">{listClientItems}</ul>
			</div>
		</Paper>
		<section>
			<RowMain>
				<div className="c-services u-bg-color-blue-lightest">
					<h2 className="c-services__title u-color-blue-light">
						Co v auditu dostanete?
						<small className="u-block u-color-blue">Vše, co potřebujete k&nbsp;rychlejšímu webu</small>
					</h2>
					<div className="c-services__wrap">
						<div className="c-services__list">{listServiceItems}</div>
						<div className="c-services__info">
							<div className="b-info">
								<p className="b-info__text">
									<span className="b-info__ico"></span>
									<strong className="u-color-blue">Bonus:</strong> Licence e-booku{' '}
									<OutboundLink
										href="https://www.vzhurudolu.cz/ebook-amp/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Vzhůru do AMP
									</OutboundLink>{' '}
									pro 1–5 uživatelů a slevu 10 % na{' '}
									<OutboundLink
										href="https://www.vzhurudolu.cz/kurzy"
										target="_blank"
										rel="noopener noreferrer"
									>
										školení rychlosti webu.
									</OutboundLink>
								</p>
								<span className="b-info__img">
									<span className="b-info__img-inner">
										<img
											data-src="/img/ampbook.png"
											width="59"
											height="81"
											alt="Kniha Vzhůru do AMP"
											className="lazyload"
										/>
										<noscript>
											<img
												src="/img/ampbook.png"
												width="59"
												height="81"
												alt="Kniha Vzhůru do AMP"
											/>
										</noscript>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</RowMain>
		</section>
		<section className="anchor-before">
			<h2 className="u-vhide">Jak vypadá analýza</h2>
			<div className="c-analysis">
				<RowMain>
					<div className="c-analysis__inner">
						<Carousel>{listAnalysisItems}</Carousel>
					</div>
				</RowMain>
			</div>
		</section>
		<section id="audit" className="anchor">
			<div className="row-main">
				<div className="b-order">
					<div className="b-order__inner">
						<h2 className="b-order__title u-color-blue-light" id="order">
							<Clipboard />
							Objednávka <small className="u-block u-color-blue">auditu pro zrychlení webu</small>
						</h2>
						<div className="b-order__form">
							<ContactForm />
						</div>
						<div className="b-order__info">
							<h3 className="b-order__info-title">
								30&nbsp;000&nbsp;Kč <small>Cena auditu bez DPH</small>
							</h3>
							<p>
								Výstupem auditu je PDF dokument obsahující 30–40 stránek s popisem konkrétních problémů
								a doporučení k&nbsp;jejich odstranění. Audit webu vyhotovíme do 14 dnů od dodání všech
								podkladů.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="b-team">
			<div className="row-main">
				<h2 id="team" className="b-team__title">
					Jsme <span className="u-color-blue-light">zkušený tým</span>
					<small className="u-block">pro optimalizaci rychlosti webových stránek</small>
				</h2>
				<div className="b-team__persons">
					<div className="b-team__inner">
						<div id="mmichalek" className="b-team__item">
							<figure className="b-team__photo">
								<picture>
									<source data-srcset="/img/mmichalek.webp" type="image/webp" />
									<img
										data-src="/img/mmichalek.jpg"
										alt="Martin Michálek"
										width="400"
										height="401"
										className="lazyload"
									/>
									<noscript>
										<img src="/img/mmichalek.jpg" alt="Martin Michálek" width="400" height="401" />
									</noscript>
								</picture>
								<span className="b-team__logo">
									<span className="logo-vd">
										<VzhuruDolu />
									</span>
								</span>
							</figure>
							<div className="b-team__body">
								<h3 className="b-team__name">Martin Michálek</h3>
								<div className="b-team__about">
									<p>
										Nezávislý konzultant rychlosti webu a AMP. Píše blog{' '}
										<OutboundLink
											href="https://www.vzhurudolu.cz/"
											target="_blank"
											rel="noopener noreferrer"
										>
											Vzhůru dolů
										</OutboundLink>{' '}
										a je autorem{' '}
										<OutboundLink
											href="https://www.vzhurudolu.cz/ebooky"
											target="_blank"
											rel="noopener noreferrer"
										>
											tří e-booků
										</OutboundLink>
										. Specializuje se na správné měření a UX během načítání webu.
									</p>
								</div>
							</div>
						</div>
						<div id="mmatuska" className="b-team__item">
							<figure className="b-team__photo">
								<picture>
									<source data-srcset="/img/mmatuska.webp" type="image/webp" />
									<img
										data-src="/img/mmatuska.jpg"
										alt="Michal Matuška"
										width="400"
										height="401"
										className="lazyload"
									/>
									<noscript>
										<img src="/img/mmatuska.jpg" alt="Michal Matuška" width="400" height="401" />
									</noscript>
								</picture>
								<span className="b-team__logo">
									<span className="logo-sk">
										<Superkoders />
									</span>
								</span>
							</figure>
							<div className="b-team__body">
								<h3 className="b-team__name">Michal Matuška</h3>
								<div className="b-team__about">
									<p>
										Technický ředitel frontendového oddělení společnosti{' '}
										<OutboundLink
											href="https://superkoders.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											SUPERKODERS
										</OutboundLink>
										. Specialista na technickou performance, fungování prohlížečů a výkon
										JavaScriptu.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</Layout>
);
